/*
=====================================
    Section E
=====================================
*/
import {addClass, removeClass, isMobile}  from './helper.js';
import { gsap, Power1 } from "gsap";

export class SeqE {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = app.content['seqe'];
        this.el = document.getElementById('js-seq-e');
        this.grid = document.getElementById('js-grid-e');
        
        this.preview = {
            image: new Image(),
            isLoaded: false,
            src: new URL("../../src/media/C_ShowerHead_Q2_1/00136.jpg", import.meta.url )
        }

        this.imageContainer = document.getElementById('js-seqe-image');
        this.showerheadIMG;
        if(!isMobile()){
            this.showerheadIMG = new URL("../../src/media/images/Maschine_ShowShowerHead.png", import.meta.url );
        } else  {
            this.showerheadIMG = new URL("../../src/media/images/Maschine_ShowShowerHeadMobile.png", import.meta.url );
        }

        this.canvas = document.getElementById('js-seqe-canvas');
        
        gsap.registerEffect({
            'name': 'scrollINE',
            effect: (targets, config) => {
                return gsap.from(targets, {
                    autoAlpha: 0,
                    yPercent: config.y,
                    xPercent: config.x,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.el,
                        start: config.start,
                        end: "bottom 100%",
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });

        this.tlE2 = gsap.timeline({
            paused: true,
            defaults: {
                duration: 1,
                autoAlpha: 0,
                opacity: 0
            }
        });

        this.tlE3 = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.14
            }
        });

        this.tlEimg;
        this.createDOM();

        if(!isMobile()){
            window.addEventListener( 'resize', this.calcMediaContainer.bind(this), false);
        }
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqe-t-${i}`).innerHTML = this.content[i];
        }

        const imagesToLoad = [this.app.imgSequences.loadImage(this.preview, 0)];
        Promise.all(imagesToLoad).then(() => {

            if(!isMobile()){    
                this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceE');
                this.app.imgSequences.loadSequence('sequnceE', true);
            } else {
                const mobileImg = `
                    <div class="pc__seqe-image-mobile">
                        <img src="${this.preview.image.src}">
                    </div>
                `;
                this.grid.insertAdjacentHTML('afterend', mobileImg);
            }
        });    

        const image = `<img id="js-img-showerhead" class="loadImg" src="${this.showerheadIMG}" alt="The Showerhead"/>`
        this.imageContainer.insertAdjacentHTML('beforeend', image);
        const imageToLoad = this.app.loadImage(document.getElementById('js-img-showerhead'));
        Promise.all([imageToLoad]).then((data) => {
            addClass(data[0], 'loaded');
        });

        //  calc the canvas
        
        if(!isMobile()){
            this.calcMediaContainer();
        }
        
        if(!isMobile()){
            this.tlE2
                .to(".pc__seqe-t-h", {yPercent: -100},)
                .to(".pc__seqe-t-g", {yPercent: -100}, '<')
                .to(".pc__seqe-t-a", {yPercent: -100}, '<+0.1')
                .to(".pc__seqe-t-b", {yPercent: -100}, '<')
                .to(".pc__seqe-t-c", {yPercent: -100}, '<')
                .to(".pc__seqe-t-d", {yPercent: -100}, '<')
                .to(".pc__seqe-t-e", {yPercent: -100}, '<')
                .to(".pc__seqe-t-f", {yPercent: -100}, '<')
                .to(".pc__seqe-t-g", {yPercent: -100}, '<')

        } else {

            this.tlE3
                .fromTo(".pc__seqe-t-c", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqe-t-c", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqe-t-d", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqe-t-d", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqe-t-e", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
                .to(".pc__seqe-t-e", {yPercent: -100, autoAlpha: 0, opacity: 0})
                .fromTo(".pc__seqe-t-f", {yPercent: 100, autoAlpha: 0, opacity: 0}, {yPercent: 0, autoAlpha: 1, opacity: 1})
        }

        
    }
    calcMediaContainer(){

        const recSec = this.el.getBoundingClientRect();
        
        // Calc Canvas
        const rectC = document.getElementById('js-grid-e-2').getBoundingClientRect();
        document.getElementById('js-seqe-canvas').style.height = `${recSec.bottom - rectC.y}px`;
        // Calc Image
        const rectImg = document.getElementById('js-grid-e-1').getBoundingClientRect();
        this.imageContainer.style.height = `${recSec.bottom - rectImg.y}px`;
    }

    runTLEimg(){
        const seq = this.app.imgSequences.sequences.sequnceE;

        this.tlEimg = gsap.timeline({
            defaults: {
                duration: .5,
                ease: 'none'
            },
            scrollTrigger: {
                trigger: this.el,
                start: "top top",
                end: "+=" + window.innerHeight * 3,
                scrub: 0.5,
                pin: true,
                onEnter: () => {
                    // addClass(document.getElementById('js-seqe-t-2'), 'active-point');
                },
                onEnterBack: () => {
                    if(!isMobile() && this.app.seqF.videoloaded){
                        this.app.seqF.video.pause();
                    }
                },
                onLeave: () => {
                    if(!isMobile() && this.app.seqF.videoloaded){
                        this.app.seqF.video.play();
                    }
                },
                onUpdate: (self) => {
                    const prog = self.progress.toFixed(3);
                    if(!isMobile()){
                        if(prog < 0.250){
                            addClass(document.getElementById('js-seqe-t-2'), 'active-point');
                            removeClass(document.getElementById('js-seqe-t-3'), 'active-point');
                        }
                        if(prog > 0.250){
                            removeClass(document.getElementById('js-seqe-t-2'), 'active-point');
                            addClass(document.getElementById('js-seqe-t-3'), 'active-point');
                            removeClass(document.getElementById('js-seqe-t-4'), 'active-point');
                        }

                        if(prog > 0.500){                        
                            removeClass(document.getElementById('js-seqe-t-3'), 'active-point');
                            addClass(document.getElementById('js-seqe-t-4'), 'active-point');
                            removeClass(document.getElementById('js-seqe-t-5'), 'active-point');
                        }
                        if(prog > 0.750){                        
                            removeClass(document.getElementById('js-seqe-t-4'), 'active-point');
                            addClass(document.getElementById('js-seqe-t-5'), 'active-point');
                        }

                        if(prog > 0.500){
                            const fadeOut = this.app.app.scaleValue(prog, [0.800, 1.000], [0.000, 1.000]);
                            this.tlE2.seek(fadeOut);                
                        }
                    } else {
                        const slideE = this.app.app.scaleValue(prog, [0.000, 1.000], [0.000, 1.000]);
                        this.tlE3.seek(slideE); 
                    }
                }
            }
        });
        
        const sequenceProxy = {
            frame: 0
        }
        this.tlEimg
            .to(sequenceProxy, {
                frame: seq.length,
                snap: 'frame',
                duration: seq.length/20,
                onUpdate: () => {
                    if(this.app.imgSequences.sequences.sequnceE.loaded){
                        this.app.imgSequences.setProgress(sequenceProxy.frame, 'sequnceE');
                    }
                }
            })

        gsap.effects.scrollINE('.pc__seqe-t-a', {y: 100, x: 0, start: "top 70%"});
        gsap.effects.scrollINE('.pc__seqe-t-b', {y: 100, x: 0, start: "top 60%"});
        gsap.effects.scrollINE('.pc__seqe-t-h', {y: 100, x: 0, start: "top 50%"});
        gsap.effects.scrollINE('.pc__seqe-t-g', {y: 100, x: 0, start: "top 30%"});

        if(!isMobile()){
            gsap.effects.scrollINE('.pc__seqe-t-c', {y: 100, x: 0, start: "top 50%"});
            gsap.effects.scrollINE('.pc__seqe-t-d', {y: 100, x: 0, start: "top 40%"});
            gsap.effects.scrollINE('.pc__seqe-t-e', {y: 100, x: 0, start: "top 30%"});
            gsap.effects.scrollINE('.pc__seqe-t-f', {y: 100, x: 0, start: "top 20%"});
        }

        this.scrolltrigger.refresh();
    }

}