/*
=====================================
    Section C
=====================================
*/
import {addClass, removeClass, isMobile}  from './helper.js';
import { gsap } from "gsap";

export class SeqC {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = this.app.content['seqc'];
        this.el = document.getElementById('js-seq-c');

        this.preview = {
            image: new Image(),
            isLoaded: false,
            src: new URL("../../src/media/B_Closeups_15fps_Q2_4/00051.jpg", import.meta.url )
        }

        gsap.registerEffect({
            'name': 'scrollINC',
            effect: (targets, config) => {
                return gsap.from(targets, {
                    autoAlpha: 0,
                    yPercent: 100,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.el,
                        start: config.start,
                        end: "bottom 50%",
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });
        
        this.cOut = gsap.timeline({
            paused: true,
        });

        this.cOut
            .to(".pc__seqc-t-c-span", { autoAlpha: 0, yPercent: -100, opacity: 0, blur: 8 });

        this.dIn = gsap.timeline({
            paused: true,
        });

        this.dIn
            .to(".pc__seqc-t-d", { autoAlpha: 1, yPercent: -100, opacity: 1, blur: 0 });
        gsap.set(".pc__seqc-t-d", { blur: 8 });
        
        this.dOut = gsap.timeline({
            paused: true,
        });
        this.dOut
            .to(".pc__seqc-t-d", { autoAlpha: 0, yPercent: -200, opacity: 0, blur: 8 });

        this.eIn = gsap.timeline({
            paused: true,
        });
        this.eIn
            .to(".pc__seqc-t-e", { autoAlpha: 1, yPercent: -200, opacity: 1, blur: 0 });
        gsap.set(".pc__seqc-t-e", { blur: 8 });
        

        this.tlCimg;
        this.createDOM();

        if(isMobile()){
            
        }

    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqc-t-${i}`).innerHTML = this.content[i];
        }
        const imagesToLoad = [this.app.imgSequences.loadImage(this.preview, 0)];
        Promise.all(imagesToLoad).then(() => {
            this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceB');
            if(!isMobile()) {
                this.app.imgSequences.loadSequence('sequnceB', true);
            } else {
                window.addEventListener('resize', this.resizeDom.bind(this));
            }
            
        });
    }

    resizeDom(){
        this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceB');
    }

    runTLCimg(){

        const seq = this.app.imgSequences.sequences.sequnceB;

        this.tlc = gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                start: "top top",
                end: "+=" + window.innerHeight * 5,
                scrub: 0.5,
                pin: true,
                // markers: true,
                onEnter: () => {
                    document.documentElement.setAttribute('data-theme', 'dark');
                },
                onEnterBack: () => {
                    // this.tlC.reverse();
                },
                onLeaveBack: () => {
                    document.documentElement.setAttribute('data-theme', 'light');
                },
                onUpdate: (self) => {
                    const prog = self.progress.toFixed(3);

                    

                    const seekCout = this.app.app.scaleValue(prog, [0.200, 0.400], [0.0, 1.0]);
                    this.cOut.seek(seekCout);
                    const seekDin = this.app.app.scaleValue(prog, [0.200, 0.400], [0.0, 1.0]);
                    this.dIn.seek(seekDin);
                    const seekDout = this.app.app.scaleValue(prog, [0.600, 0.800], [0.0, 1.0]);
                    this.dOut.seek(seekDout);
                    const seekEin = this.app.app.scaleValue(prog, [0.600, 0.800], [0.0, 1.0]);
                    this.eIn.seek(seekEin);

                    if(prog > 0.000 && prog < 0.300){
                        addClass(document.getElementsByClassName('ready-underline')[0] ,'ready');
                    } else {
                        removeClass(document.getElementsByClassName('ready-underline')[0] ,'ready');
                    }
                    if(prog > 0.300 && prog < 0.700){
                        addClass(document.getElementsByClassName('ready-underline')[1] ,'ready');
                    } else {
                        removeClass(document.getElementsByClassName('ready-underline')[1] ,'ready');
                    }
                    if(prog > 0.700){
                        addClass(document.getElementsByClassName('ready-underline')[2] ,'ready');
                    } else {
                        removeClass(document.getElementsByClassName('ready-underline')[2] ,'ready');
                    }
                }
            }
        });

        const sequenceProxy = {
            frame: 0
        }

        this.tlc
            .to(sequenceProxy, {
                frame: seq.length,
                snap: 'frame',
                duration: seq.length/20,
                onUpdate: () => {
                    if(this.app.imgSequences.sequences.sequnceB.loaded){
                        this.app.imgSequences.setProgress(sequenceProxy.frame, 'sequnceB');
                    }
                }
        })


        gsap.effects.scrollINC('.pc__seqc-t-a', {start: "top 70%"});
        gsap.effects.scrollINC('.pc__seqc-t-b', {start: "top 60%"});
        gsap.effects.scrollINC('.pc__seqc-t-c', {start: "top 50%" });
        
        this.scrolltrigger.refresh();

    }

}