/*
=====================================
    Section H
=====================================
*/
import { gsap } from "gsap";

export class SeqH {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = this.app.content['seqh'];
        this.el = document.getElementById('js-seq-h');
        this.preview = {
            image: new Image(),
            isLoaded: false,
            src: new URL("../../src/media/D_Coffee_Drip_15fps_Q2_3/00039.jpg", import.meta.url )
        }

        this.scrollContent = document.getElementById('js-seq-h-content');


        gsap.registerEffect({
            'name': 'scrollOverH',
            effect: (targets, config) => {
                return gsap.to(targets, {
                    y: -200,
                    scrollTrigger: {
                        trigger: this.el,
                        start: 'top top',
                        end: "+=" + window.innerHeight * 4,
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });    
        this.tlHimg;

        this.createDOM();
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqh-t-${i}`).innerHTML = this.content[i];
        }
        const imagesToLoad = [this.app.imgSequences.loadImage(this.preview, 0)];
        Promise.all(imagesToLoad).then(() => {
            this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceH');        
            this.app.imgSequences.loadSequence('sequnceH', true);
        });
    }


    runTLHimg(){
        const seq = this.app.imgSequences.sequences.sequnceH
        const sequenceProxy = {
            frame: 0
        }

        
        this.tlHimg = gsap.timeline({
            defaults: {
                duration: .5,
                ease: 'none'
            },
            scrollTrigger: {
                trigger: this.el,
                start: "top top",
                end: "+=" + window.innerHeight * 3,
                scrub: 0.5,
                pin: true,
                onLeave: () => {
                    // this.app.scrollToSeq(this.app.seqI.el, 100);
                }
            }
        });

        this.tlHimg
            .to(sequenceProxy, {
                frame: seq.length,
                snap: 'frame',
                duration: seq.length/20,
                onUpdate: () => {
                    if(this.app.imgSequences.sequences.sequnceH.loaded){
                        this.app.imgSequences.setProgress(sequenceProxy.frame, 'sequnceH');
                    }
                }
            })
        
        gsap.effects.scrollOverH('.pc__seqh-t-a', {});
        this.scrolltrigger.refresh();
    }

}