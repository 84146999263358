/*
=====================================
    Section F
=====================================
*/
import {addClass, isMobile}  from './helper.js';
import { gsap } from "gsap";

export class SeqF {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = app.content['seqf'];
        this.el = document.getElementById('js-seq-f');

        gsap.registerEffect({
            'name': 'scrollINF',
            effect: (targets, config) => {
                return gsap.from(targets, {
                    autoAlpha: 0,
                    yPercent: 100,
                    opacity: 0,
                    blur: 8,
                    scrollTrigger: {
                        trigger: this.el,
                        start: config.start,
                        end: "bottom 100%",
                        scrub: 0.5,
                        // markers: true
                    }
                }); 
            }
        });

        this.videoUrl = new URL("../../src/media/videos/Kaffee_Pulver_Video_25FS_16zu9.mp4", import.meta.url );
        this.videoUrl = "" + this.videoUrl;
        this.previewUrl = new URL("../../src/media/videos/video_screenshot_min.png", import.meta.url );
        this.previewUrl = "" + this.previewUrl;
        this.preview = document.getElementById('video-seq-f-preview');
        this.video = document.getElementById('video-seq-f-src');
        this.videoloaded = false;
        this.tlFimg;
        this.createDOM();
    
    }
    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqf-t-${i}`).innerHTML = this.content[i];
        }
        
        
        if(this.previewUrl){
            this.preview.src = this.previewUrl;
            const imageToLoad = this.app.loadImage(this.preview);
            Promise.all([imageToLoad]).then((data) => {
                addClass(data[0], 'loaded');
            });
        }
    }

    addSRC(){
        
        if(this.videoUrl){
            this.video.src = this.videoUrl;
        }
        const imagesToLoad  = this.loadSRC();
        Promise.all([imagesToLoad]).then(() => {
            console.log(`video is loaded 🚨`);
            this.videoloaded = true;
            this.preview.style.display = 'none';
            this.video.style.display = 'block';
            
            // // 3D Model
            let url = new URL("../../src/media/models/ShowShowerHead_GTLF_White_v06_draco.glb", import.meta.url );
            url = "" + url;
            this.app.seqI.loadMesh(url);
            
        });
    }
    loadSRC(){
        return new Promise(resolve => {
        
            const videoLoad = () => {
                let found = false;
                if ( this.video.readyState === 4 ) {
                    found = true;
                    resolve();
                }
        
                if(!found) setTimeout(videoLoad.bind(this), 500);
            }
            videoLoad();
        });

    }
    runTLFimg(){

        this.tlFimg = gsap.timeline({
            scrollTrigger: {
                trigger: this.el,
                start: "top 50%",
                onEnterBack: () => {
                    if(!isMobile() && this.videoloaded){
                        this.video.play();
                    }
                },
                onUpdate: (self) => {
                    const prog = self.progress.toFixed(3);
                    if(prog > 0.334){
                        document.documentElement.setAttribute('data-theme', 'light');  
                    } else {
                        document.documentElement.setAttribute('data-theme', 'dark');
                        
                    }
                }
            }
        });
        gsap.effects.scrollINF('.pc__seqf-t-a', {start: "top 50%"});
        gsap.effects.scrollINF('.pc__seqf-t-b', {start: "top 40%"});
        gsap.effects.scrollINF('.pc__seqf-t-c', {start: "top 30%"});
        gsap.effects.scrollINF('.pc__seqf-t-d', {start: "top 20%"});

        this.scrolltrigger.refresh();

    }
}