/*
=====================================
    Section A
=====================================
*/
import {removeClass, isMobile}  from './helper.js';
import { gsap, Power1, Power2 } from "gsap";

gsap.registerEffect({
	name: 'textOut1',
	effect: (targets, config) => {
		return gsap.to(targets, { autoAlpha: 0, blur: 8, opacity: 0, duration: 1, paused: true});
	}
});

export class SeqA {
    constructor(app){
        this.app = app;
        this.scrolltrigger = this.app.scrolltrigger;
        this.content = this.app.content['seqa'];
        this.el = document.getElementById('js-seq-a');

        this.preview = {
            image: new Image(),
            isLoaded: false,
            src: new URL("../../src/media/A_Intro_15fps_Q2_2/00000.jpg", import.meta.url )
        }

        this.sequenceProxy = {
            frame: 0
        }
        
        this.tlA = gsap.timeline({
            paused: true,
            onComplete: () =>{
                console.log('allow scroll 👇');
                this.app.body.style.overflow = 'auto';
                
                // Init Scrolltrigger Sequences
                // this.app.seqC.runTLCimg();
                // this.app.seqD.runTLDimg();
                // this.app.seqE.runTLEimg();
                // this.app.seqF.runTLFimg();
                // this.app.seqG.runTLGimg();
                // this.app.seqH.runTLHimg();
                // if(!isMobile()){
                //     this.app.seqI.runTLIimg();
                // }
                // this.app.seqJ.runTLJimg();
                // this.app.seqK.runTLKimg();
            }
        });
        this.tlA
            // Zwilling Enfinigy
            .from(".pc__seqa-t-a", { autoAlpha: 0, y: this.app.winsize.height, blur: 8, opacity: 0, duration: 1.5, ease: Power2.easeOut})
            // Entdecke
            .from(".pc__seqa-t-b", { autoAlpha: 0, yPercent: 100, opacity: 0, blur: 8, duration: 0.75, ease: Power1.easeOut }, '-=0.65')
            // Aromatischen
            .from(".pc__seqa-t-c", { autoAlpha: 0, yPercent: 100, opacity: 0, blur: 8, duration: 0.75, ease: Power1.easeOut }, '-=0.65')
            // Kaffeegenuss
            .from(".pc__seqa-t-d", { autoAlpha: 0, yPercent: 100, opacity: 0, blur: 8, duration: 0.75, ease: Power1.easeOut } , '-=0.65')
            // In Barista-Qualität
            .from(".pc__seqa-t-e", { autoAlpha: 0, yPercent: 200, opacity: 0, blur: 8, duration: 1, ease: Power1.easeOut  } , '-=0.25')


        this.tlAimg;
        this.createDOM();

        
    }

    createDOM(){
        for (let i = 0; i < this.content.length; i++) {
            document.getElementById(`js-seqa-t-${i}`).innerHTML = this.content[i];
        }

        const imagesToLoad = [this.app.imgSequences.loadImage(this.preview, 0)];
        Promise.all(imagesToLoad).then(() => {
            this.app.imgSequences.setPrewProgress(this.preview.image, 'sequnceA');        
            this.app.imgSequences.loadSequence('sequnceA', true);
            this.startTLA();
            this.runTLAimg();
            removeClass(document.body, 'loading');
        });
    }


    startTLA(){
        this.tlA.play();
    }

    runTLAimg(){
        const seq = this.app.imgSequences.sequences.sequnceA
        const a = gsap.effects.textOut1(".pc__seqa-t-a", {paused: true});
        const b = gsap.effects.textOut1(".pc__seqa-t-b", {paused: true});
        const c = gsap.effects.textOut1(".pc__seqa-t-c", {paused: true});
        const d = gsap.effects.textOut1(".pc__seqa-t-d", {paused: true});
        const e = gsap.effects.textOut1(".pc__seqa-t-e", {paused: true});

        const sequenceProxy = {
            frame: 0
        }
        this.tlAimg = gsap.timeline({
            defaults: {
                duration: .5,
                ease: 'none'
            },
            scrollTrigger: {
                trigger: this.el,
                start: "top top",
                end: "+=" + window.innerHeight * 4,
                scrub: 0.5,
                pin: true,
                onLeave: () => {
                    // this.app.scrollToSeq(this.app.seqC.el, 100);
                },
                onUpdate: (self) => {
                    const prog = self.progress.toFixed(3);
                    if(prog > 0.150){
                        const scaletextOut1 = this.app.app.scaleValue(prog, [0.2, 0.3], [0.0, 1.0]);
                        a.seek( parseFloat(scaletextOut1.toFixed(1)) , true);
                        b.seek( parseFloat(scaletextOut1.toFixed(1)) , true);
                        c.seek( parseFloat(scaletextOut1.toFixed(1)) , true);
                        d.seek( parseFloat(scaletextOut1.toFixed(1)) , true);
                        e.seek( parseFloat(scaletextOut1.toFixed(1)) , true);
                    }
                    if(prog < 0.500 && this.app.seqB.played == true){
                        this.app.seqB.reverseTL();
                    }
                    if(prog > 0.500 && this.app.seqB.played == false){
                        this.app.seqB.playTL();
                    }
                    if(prog > 0.7 && prog < 1.0){
                        const scaletextBOut = this.app.app.scaleValue(prog, [0.7, 0.9], [1.0, 0.7]);
                        const fadetextBOut2 = this.app.app.scaleValue(prog, [0.7, 0.9], [1.0, 0.0]);
                        seq.textSeqB.style.transform = `scale(${scaletextBOut})`;
                        seq.textSeqB.style.opacity = fadetextBOut2.toFixed(1);
                    }
                }
            }
        });

        this.tlAimg
            .to(this.sequenceProxy, {
                frame: seq.length,
                snap: 'frame',
                duration: seq.length/20,
                onUpdate: () => {
                    
                    if(this.app.imgSequences.sequences.sequnceA.loaded){
                        this.app.imgSequences.setProgress(this.sequenceProxy.frame, 'sequnceA');
                    }
                }
            })

        this.scrolltrigger.refresh();

    }

}